function Icon(props) {
  // let imgSrc = props.match.params.sku ? props.match.params.sku : NoImg;
  const ImageReidrect = (sku) => {
    window.location.href = `https://cdn.shopify.com/s/files/1/0532/6018/9875/files/${sku}_100.jpg`;
    return null;
  };

  return ImageReidrect(props.match.params.sku);
}
//

export default Icon;
