import React from "react";
import { Route, Switch } from "react-router-dom";

//pages
import Welcome from "./Welcome.js";

function Icon(props) {
  const { texxture, di, wholesale } = props.urls;
  const reidrect = (link) => {
    window.location.href = link;
    return null;
  };

  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact component={() => reidrect(di)} />
        <Route path="/retailers" component={() => reidrect(wholesale)} />
        <Route
          path="/all/gifts"
          component={() => reidrect(`${di}/collections/small-gifts`)}
        />
        <Route
          path="/all/storage-org"
          component={() => reidrect(`${di}/collections/all-storage`)}
        />
        <Route
          path="/all/home"
          component={() =>
            reidrect(`${texxture}/collections/decor-accessories`)
          }
        />
        <Route
          path="/all/seasons-holidays"
          component={() => reidrect(`${texxture}/collections/holidays-gifts`)}
        />
        <Route
          path="/all/sale"
          component={() => reidrect(`${di}/collections/sale`)}
        />
        <Route
          path="/collection/meshworks-shelving"
          component={() => reidrect(`${di}/collections/meshwork-shelving-1`)}
        />
        <Route
          path="/collection/alpine-ornaments/christmas"
          component={() => reidrect(`${texxture}/collections/alpine-ornaments`)}
        />
        <Route
          path="/collection/aphrodite/vases"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=aphrodite`)
          }
        />
        <Route
          path="/collection/balihai/tabletop"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=balihai`)
          }
        />
        <Route
          path="/collection/basic-wax/candlelight"
          component={() => reidrect(`${texxture}/collections/basic-wax`)}
        />
        <Route
          path="/collection/branch/tabletop"
          component={() => reidrect(`${texxture}/collections/branch`)}
        />
        <Route
          path="/collection/buddies/vases"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=buddies`)
          }
        />
        <Route
          path="/collection/cooper-collection/bath"
          component={() =>
            reidrect(`${di}/collections/bathroom-storage/nav_Storage`)
          }
        />
        <Route
          path="/collection/copper-cabo/office"
          component={() =>
            reidrect(
              `${di}/pages/search-results?q=cabo&narrow=%5B%5B"Material"%2C"mat_copper"%5D%5D&disable_semantics=1`
            )
          }
        />
        <Route
          path="/collection/creatures1/gelgems"
          component={() => reidrect(`${di}/collections/gelgems-creatures`)}
        />
        <Route
          path="/collection/digit/office"
          component={() =>
            reidrect(`${di}/collections/office-storage/nav_Storage`)
          }
        />
        <Route
          path="/collection/edison-desk/office"
          component={() => reidrect(`${di}/collections/edison-desk`)}
        />
        <Route
          path="/collection/edison-kitchen/kitchen"
          component={() => reidrect(`${di}/collections/edison-kitchen`)}
        />
        <Route
          path="/collection/fall/fall"
          component={() => reidrect(`${di}/collections/gelgems-fall`)}
        />
        <Route
          path="/collection/flexket/home-storage"
          component={() => reidrect(`${di}/collections/flexket`)}
        />
        <Route
          path="/collection/flowers1/gelgems"
          component={() => reidrect(`${di}/collections/gelgems-flowers`)}
        />
        <Route
          path="/collection/flurry/christmas"
          component={() => reidrect(`${texxture}/collections/winter`)}
        />
        <Route
          path="/collection/frisco/office"
          component={() =>
            reidrect(`${di}/collections/office-storage/nav_Storage`)
          }
        />
        <Route
          path="/collection/gordian/home-storage"
          component={() => reidrect(`${di}/collections/baskets-bins`)}
        />
        <Route
          path="/collection/jackfrost-ornaments/christmas"
          component={() => reidrect(`${texxture}/collections/winter`)}
        />
        <Route
          path="/collection/just-for-fun1/gelgems"
          component={() => reidrect(`${di}/collections/gelgems-just-for-fun`)}
        />
        <Route
          path="/collection/lexington/tabletop"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=lexington`)
          }
        />
        <Route
          path="/collection/lincoln/kitchen"
          component={() =>
            reidrect(`${di}/collections/kitchen-storage/nav_Storage`)
          }
        />
        <Route
          path="/collection/love1/gelgems"
          component={() => reidrect(`${di}/collections/gelgems-love`)}
        />
        <Route
          path="/collection/mesh-desk/office"
          component={() =>
            reidrect(
              `${di}/pages/search-results?q=meshworks&narrow=%5B%5B"Category"%2C"nav_Desktop"%5D%5D&disable_semantics=1`
            )
          }
        />
        <Route
          path="/collection/mesh-kitchen/kitchen"
          component={() =>
            reidrect(
              `${di}/collections/meshworks?narrow=%5B%5B"Room"%2C"room_Kitchen"%5D%5D&sort_by=relevency&disable_semantics=1`
            )
          }
        />
        <Route
          path="/collection/meshworks-shelving/home-storage"
          component={() => reidrect(`${di}/collections/meshwork-shelving-1`)}
        />
        <Route
          path="/collection/natural-cabo/office"
          component={() =>
            reidrect(
              `${di}/pages/search-results?q=cabo&narrow=%5B%5B"Category"%2C"nav_Desktop"%5D%5D&disable_semantics=1`
            )
          }
        />
        <Route
          path="/collection/nordic-ornaments/christmas"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=nordic`)
          }
        />
        <Route
          path="/collection/nutcracker/christmas"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=nutcracker`)
          }
        />
        <Route
          path="/collection/savoy/home-storage"
          component={() => reidrect(`${di}/collections/baskets-bins`)}
        />
        <Route
          path="/collection/spirit-candles/candlelight"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=spirit+candle`)
          }
        />
        <Route
          path="/collection/squish-bath/bath"
          component={() =>
            reidrect(`${di}/collections/bathroom-storage/nav_Storage`)
          }
        />
        <Route
          path="/collection/takara-tabletop-collection/tabletop"
          component={() =>
            reidrect(
              `${texxture}/collections/tabletop-bar?page=1&rb_filter_ptag_ac5523275e93c45e4c6b7bf11e96aa72=Takara`
            )
          }
        />
        <Route
          path="/collection/tannenbaum/christmas"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=tannenbaum`)
          }
        />
        <Route
          path="/collection/vinea-kitchen/kitchen"
          component={() =>
            reidrect(`${di}/collections/kitchen-storage/nav_Storage`)
          }
        />
        <Route
          path="/collection/vision/vases"
          component={() =>
            reidrect(`${texxture}/pages/search-results-page?q=vision`)
          }
        />
        <Route
          path="/collection/woodland-creatures/fall"
          component={() =>
            reidrect(
              `${texxture}/pages/search-results-page?q=woodland+creatures`
            )
          }
        />
        <Route
          path="/gifts/gelgems"
          component={() => reidrect(`${di}/collections/gelgems`)}
        />
        <Route
          path="/home/candlelight"
          component={() => reidrect(`${texxture}/collections/candles-lighting`)}
        />
        <Route
          path="/home/tabletop"
          component={() => reidrect(`${di}/collections/tabletop-bar`)}
        />
        <Route
          path="/home/vases"
          component={() => reidrect(`${texxture}/collections/vases`)}
        />
        <Route
          path="/seasons-holidays/christmas"
          component={() => reidrect(`${texxture}/collections/winter`)}
        />
        <Route
          path="/seasons-holidays/fall"
          component={() => reidrect(`${texxture}/collections/autumn`)}
        />
        <Route
          path="/storage-org/bath"
          component={() =>
            reidrect(`${di}/collections/bathroom-storage/nav_Storage`)
          }
        />
        <Route
          path="/storage-org/home-storage"
          component={() => reidrect(`${di}/collections/all-storage`)}
        />
        <Route
          path="/storage-org/kitchen"
          component={() =>
            reidrect(`${di}/collections/kitchen-storage/nav_Storage`)
          }
        />
        <Route
          path="/storage-org/office"
          component={() =>
            reidrect(`${di}/collections/office-storage/nav_Storage`)
          }
        />

        <Route
          path="/blog"
          component={() => reidrect(`${texxture}/blogs/news`)}
        />
        <Route component={Welcome} />
      </Switch>
    </React.Fragment>
  );
}

export default Icon;

//Route path={["/welcome", "/account", "/basket"]} component={Welcome} />
