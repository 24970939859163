import React from "react";

import Did from "../media/di-desk.png";
import Dim from "../media/di-mob.png";
import Tid from "../media/tex-desk.png";
import Tim from "../media/tex-mob.png";

import DiLogo from "../comps/Logo.js";
import TexLogo from "../comps/TexLogo.js";

function Icon() {
  return (
    <div className="newPage App-header">
      <h1>Hello there!</h1>
      <p>
        We recently went through a major personality discovery and have
        separated our products into two separate lines. Our <b>home décor</b>{" "}
        products you know and love have made their way over to our new brand
        called{" "}
        <b>
          <a
            href="https://texxturehome.com"
            style={{ color: "#FFAB42", textDecoration: "none" }}
          >
            texxture
          </a>
        </b>
        . For all of our <b>fun, whimsical, and organizational products</b> you
        can still find them under{" "}
        <b>
          <a
            href="https://designideas.net"
            style={{ color: "#2F80ED", textDecoration: "none" }}
          >
            Design Ideas
          </a>
        </b>
        !
      </p>
      <div className="link-dip-cont">
        <a href="https://texxturehome.com">
          <div className="img-wrap">
            <img className="decktop-img" src={Tid} alt="DI Router image3" />
            <img className="mob-img" src={Tim} alt="DI Router imag4e" />
          </div>
          <div className="divider" />
          <TexLogo />
        </a>
        <a href="https://designideas.net">
          <div className="img-wrap">
            <img className="decktop-img" src={Did} alt="DI Router image1" />
            <img className="mob-img" src={Dim} alt="DI Router image2" />
          </div>
          <div className="divider" />
          <DiLogo />
        </a>
      </div>
      <p>
        <br />
        Thank you for your support, we look forward to seeing you over on our{" "}
        <b>new</b> web pages!
      </p>
    </div>
  );
}

export default Icon;
