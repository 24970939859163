function Products(props) {
  const { handle } = props;
  const { texxture, di, wholesale } = props.urls;

  const linkMap = {
    "adam-the-doodles-man": `${di}/products/adam-the-doodles-man`,
    "alphaart-letters-afuschsia": `${di}/products/alphaart-letters-lower-a`,
    "alphaart-letters-blime-green": `${di}/products/alphaart-b`,
    "alphaart-letters-borange": `${di}/products/alphaart-letters-upper-b`,
    "alphaart-letters-cblue": `${di}/products/alphaart-c`,
    "alphaart-letters-cred": `${di}/products/alphaart-letters-upper-c`,
    "alphaart-letters-dkelly-green": `${di}/products/alphaart-letters-upper-d`,
    "alphaart-letters-dyellow": `${di}/products/alphaart-d`,
    "alphaart-letters-elavender": `${di}/products/alphaart-e`,
    "alphaart-letters-eyellow": `${di}/products/alphaart-letters-upper-e`,
    "alphaart-letters-ffuschia": `${di}/products/alphaart-letters-upper-f`,
    "alphaart-letters-fpurple": `${di}/products/alphaart-f`,
    "alphaart-letters-gblue": `${di}/products/alphaart-letters-lower-g`,
    "alphaart-letters-gorange": `${di}/products/alphaart-letters-upper-g`,
    "alphaart-letters-hlime-green": `${di}/products/alphaart-letters-upper-h`,
    "alphaart-letters-hred": `${di}/products/alphaart-h`,
    "alphaart-letters-iorange": `${di}/products/alphaart-i`,
    "alphaart-letters-iyellow": `${di}/products/alphaart-letters-upper-i`,
    "alphaart-letters-jkelly-green": `${di}/products/alphaart-j`,
    "alphaart-letters-jlavender": `${di}/products/alphaart-letters-upper-j`,
    "alphaart-letters-kfuschia": `${di}/products/alphaart-letters-upper-k`,
    "alphaart-letters-kpurple": `${di}/products/alphaart-k`,
    "alphaart-letters-llavender": `${di}/products/alphaart-letters-upper-l`,
    "alphaart-letters-mblue": `${di}/products/alphaart-letters-upper-m`,
    "alphaart-letters-mred": `${di}/products/alphaart-m`,
    "alphaart-letters-nfuschia": `${di}/products/alphaart-letters-upper-n`,
    "alphaart-letters-nkelly-green": `${di}/products/alphaart-n`,
    "alphaart-letters-opurple": `${di}/products/alphaart-letters-upper-o`,
    "alphaart-letters-oyellow": `${di}/products/alphaart-o`,
    "alphaart-letters-pfuschia": `${di}/products/alphaart-letters-upper-p`,
    "alphaart-letters-pkelly-green": `${di}/products/alphaart-p`,
    "alphaart-letters-qlavender": `${di}/products/alphaart-letters-upper-q`,
    "alphaart-letters-qpurple": `${di}/products/alphaart-letters-lower-q`,
    "alphaart-letters-rblue": `${di}/products/alphaart-letters-upper-r`,
    "alphaart-letters-rred": `${di}/products/alphaart-r`,
    "alphaart-letters-sblue": `${di}/products/alphaart-s`,
    "alphaart-letters-syellow": `${di}/products/alphaart-letters-upper-s`,
    "alphaart-letters-tlime-green": `${di}/products/alphaart-t`,
    "alphaart-letters-tred": `${di}/products/alphaart-letters-upper-t`,
    "alphaart-letters-ufuchsia": `${di}/products/alphaart-u`,
    "alphaart-letters-ulavender": `${di}/products/alphaart-letters-upper-u`,
    "alphaart-letters-vkelly-green": `${di}/products/alphaart-letters-upper-v`,
    "alphaart-letters-vpurple": `${di}/products/alphaart-v`,
    "alphaart-letters-wfuchsia": `${di}/products/alphaart-letters-upper-w`,
    "alphaart-letters-wlavender": `${di}/products/alphaart-w`,
    "alphaart-letters-xblue": `${di}/products/alphaart-letters-upper-x`,
    "alphaart-letters-xorange": `${di}/products/alphaart-x`,
    "alphaart-letters-ylime-green": `${di}/products/alphaart-letters-upper-y`,
    "alphaart-letters-yred": `${di}/products/alphaart-y`,
    "alphaart-letters-zkelly-green": `${di}/products/alphaart-z`,
    "alphaart-letters-zpurple": `${di}/products/alphaart-letters-upper-z`,
    "alphaart-numbers-apurple": `${di}/products/alphaart-letters-upper-a`,
    "alphaart-symbols-lavender": `${di}/products/alphaart-letters-symbol-amp`,
    "alpha-magnets-brass-set-80": `${di}/products/alpha-maets-set-80`,
    "alphamagnets-magnets-set-of-90": `${di}/products/alphamaets-set-90-asst`,
    "alpine-moose-set-of-4natural": `${texxture}/products/alpine-moose`,
    "alpine-ornaments-cardinalsset-of-24": `${texxture}/products/alpine-ornaments-cardinal`,
    "alpine-ornaments-set-of-24baubles": `${texxture}/products/alpine-ornaments`,
    "alpine-ornaments-set-of-24snowflakes": `${texxture}/products/alpine-ornaments-snow`,
    "alpine-ornaments-set-of-4assorted": `${texxture}/products/alpine-ornaments-set`,
    "alpine-reindeer-extra-smallnatural": `${texxture}/products/alpine-reindeer-ex-small`,
    "alpine-reindeer-extra-smallred": `${texxture}/products/alpine-reindeer-ex-small`,
    "alpine-reindeer-largenatural": `${texxture}/products/alpine-reindeer-large`,
    "alpine-reindeer-largered": `${texxture}/products/alpine-reindeer-large`,
    "alpine-reindeer-mediumnatural": `${texxture}/products/alpine-reindeer-medium`,
    "alpine-reindeer-mediumred": `${texxture}/products/alpine-reindeer-medium`,
    "alpine-reindeer-mini-asst-mod-set-8": `${texxture}/products/alpine-reindeer-mini`,
    "alpinereindeer-ornament": `${texxture}/products/alpine-reindeer-ornament`,
    "alpine-reindeer-set-of-8natural": `${texxture}/products/alpine-reindeer`,
    "alpine-sleigh-giant": `${texxture}/products/alpine-sleigh-giant`,
    "alpine-sleigh-small": `${texxture}/products/alpine-sleigh-small`,
    "alpine-tealight-trough-short": `${texxture}/products/alpine-tealight-trough`,
    "alpine-tree-extra-smallforest-green": `${texxture}/products/alpine-tree-mini`,
    "alpine-tree-largeforest-green": `${texxture}/products/alpine-tree-large`,
    "alpine-tree-mediumforest-green": `${texxture}/products/alpine-tree-medium`,
    "alpine-tree-smallforest-green": `${texxture}/products/alpine-tree-small`,
    "amalia-coffee-table": `${texxture}/products/amalia-coffee-table`,
    "animalkingdom-hook-dog": `${di}/products/animal-kingdom-hook-dog`,
    "animalkingdom-hook-horse": `${di}/products/animal-kingdom-hook-horse`,
    "animalkingdom-hook-rhino": `${di}/products/animal-kingdom-hook-rhino`,
    "aphrodite-vase-amphora": `${texxture}/products/aphrodite-vase`,
    "artworks-corkboard-set": `${di}/products/artworks-set`,
    "ashland-drawer-organizers-3-x-12-black": `${texxture}/products/ashland-drawer-org-3x12`,
    "ashland-drawer-organizers-3-x-3-black": `${texxture}/products/ashland-drawer-org-3x3`,
    "ashland-drawer-organizers-3-x-6-black": `${texxture}/products/ashland-drawer-org-3x6`,
    "ashland-drawer-organizers-3-x-9-black": `${texxture}/products/ashland-drawer-org-3x9`,
    "ashland-drawer-organizers-6-x-12-black": `${texxture}/products/ashland-drawer-org-6x12`,
    "ashland-drawer-organizers-6-x-6-black": `${texxture}/products/ashland-drawer-org-6x6`,
    "ashland-drawer-organizers-6-x-9-black": `${texxture}/products/ashland-drawer-org-6x9`,
    "aspen-tree-11.5-inches": `${texxture}/products/aspen-tree-small`,
    "aspen-tree-16.75-inches": `${texxture}/products/aspen-tree-medium`,
    "aspen-tree-22.45-inches": `${texxture}/products/aspen-tree-large`,
    "aspen-tree-31.4-inches": `${texxture}/products/aspen-tree-ex-large`,
    "aspen-tree-6.75-inches-set-of-6": `${texxture}/products/aspen-tree-set`,
    "avello-arm-chair": `${texxture}/products/avello-arm-chair`,
    "axis-bowl": `${texxture}/products/axis-bowl`,
    "bagan-box-medium": `${texxture}/products/bagan-box-large`,
    "bagan-box-small": `${texxture}/products/bagan-box-small`,
    "bagan-candleholder-gray": `${texxture}/products/bagan-candleholder`,
    "bagan-card-box-large": `${texxture}/products/bagan-card-box`,
    "bagan-pen-box": `${texxture}/products/bagan-pen-box`,
    "balihai-coasters-set-of-4heliconia": `${texxture}/products/balihai-coasters-heliconia`,
    "balihai-coasters-set-of-4pothos": `${texxture}/products/balihai-coasters-pathos`,
    "balihai-placemat-alocasia": `${texxture}/products/balihai-placemat-alocasia`,
    "balihai-placemat-heliconia": `${texxture}/products/balihai-placemat-heliconia`,
    "balihai-placemat-philodendron": `${texxture}/products/balihai-placemat-philodendron`,
    "balihai-placemat-pothos": `${texxture}/products/balihai-placemat-pathos`,
    "balihai-runner-banana": `${texxture}/products/balihai-runner-fern`,
    "balihai-runner-fern": `${texxture}/products/balihai-runner-banana`,
    "band-ruler": `${di}/products/band-ruler`,
    "barcelona-beach-chair": `${texxture}/products/barcelona-beach-chair`,
    "barcelona-side-table": `${texxture}/products/barcelona-side-table`,
    "beachstone-coasters-set-4-black": `${texxture}/products/beachstone-coasters`,
    "beachstone-coasters-set-4-white": `${texxture}/products/beachstone-coasters`,
    "beachstone-placemat-balck": `${texxture}/products/beachstone-placemat`,
    "beachstone-placemat-white1": `${texxture}/products/beachstone-placemat`,
    "beethoven-eraser": `${di}/products/beethoven-eraser`,
    "billboard-wastecan": `${di}/products/billboard-waste-can`,
    "blink-calendar": `${di}/products/blink-calendar`,
    "bloomsbury-mug-9.6-oz": `${texxture}/products/bloomsbury-mug`,
    "bloomsbury-plate": `${texxture}/products/bloomsbury-plate`,
    "bloomsbury-platter": `${texxture}/products/bloomsbury-platter`,
    "bloomsbury-teacup-4-oz": `${texxture}/products/bloomsbury-tea-cup`,
    "bloomsbury-teapot": `${texxture}/products/bloomsbury-tea-pot`,
    "boboli-hanging-terrarium-5.5-dia-x-9.5-in": `${texxture}/products/boboli-hngng-terrarium-small`,
    "boboli-hanging-terrarium-6.5-dia-x-11.5-in": `${texxture}/products/boboli-hngng-terrarium-medium`,
    "boboli-hanging-terrarium-7-dia-x-15-in": `${texxture}/products/boboli-hngng-terrarium-large`,
    "boboli-terrarium-11-dia-x-11.5-in": `${texxture}/products/boboli-terrarium-small`,
    "boboli-terrarium-12-dia-x-16-in": `${texxture}/products/boboli-terrarium-medium`,
    "boboli-terrarium-13.5-dia-x-20-in": `${texxture}/products/boboli-terrarium-large`,
    "bottle-garland-clear": `${texxture}/products/bottle-garland`,
    "bowery-candleholder-2.75-x-2.75-x-4.1-in": `${texxture}/products/bowery-candle-holder-medium`,
    "bowery-candleholder-2.75-x-2.75-x-4.9-in": `${texxture}/products/bowery-candle-holder-tall`,
    "bowery-candleholder-3.5-x-3.5-x-3.5-in": `${texxture}/products/bowery-candle-holder-short`,
    "branch-coasters-set-of-4brownacacia-wood": `${texxture}/products/branch-coasters`,
    "branch-doormat-brownacacia-wood": `${texxture}/products/branch-doormat`,
    "branch-placemat-brownacacia-wood": `${texxture}/products/branch-mat`,
    "branch-runner-brownacacia-wood": `${texxture}/products/branch-runner`,
    "brindisi-pedestal-large": `${texxture}/products/brindisi-pedestal-large`,
    "brindisi-pedestal-medium": `${texxture}/products/brindisi-pedestal-medium`,
    "brindisi-pedestal-small": `${texxture}/products/brindisi-pedestal-small`,
    "brindisi-salad-bowl-12.5-dia-x-5.1-in": `${texxture}/products/brindisi-salad-bowl-small`,
    "brindisi-salad-bowl-17-dia-x-5.9in": `${texxture}/products/brindisi-salad-bowl`,
    "brindisi-salad-servers-set-of-2": `${texxture}/products/brindisi-salad-servers`,
    "bubble-candle-clear": `${texxture}/products/bubble-candle`,
    "bubble-white-frost": `${texxture}/products/bubble-candle`,
    "bubblope-cd-mailer-black": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-blue": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-fuchsia": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-green": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-metallic-gold": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-purple": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-red": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-silver": `${di}/products/bubblope-small-envelope`,
    "bubblope-cd-mailer-teal": `${di}/products/bubblope-small-envelope`,
    "bubblope-photomailer-black": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-blue": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-fuchsia": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-green": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-metallic-gold": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-purple": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-red": `${di}/products/bubblope-large-envelope`,
    "bubblope-photomailer-teal": `${di}/products/bubblope-large-envelope`,
    "buddha-pen": `${di}/products/buddha-pen`,
    "buddy-lantern": `${texxture}/products/buddy-lantern`,
    "buddy-vase-clear": `${texxture}/products/buddy-vase`,
    "buddy-vase-set-set-6": `${texxture}/products/buddy-vase-set`,
    "buildingblocks-sculpture-chrysler-building": `${texxture}/products/buildingblocks-chrysler-building`,
    "buildingblocks-sculpture-empire-state": `${texxture}/products/buildingblocks-empire-state`,
    "busybee-pushpins-set-6": `${di}/products/busybee-pushpins-set-6`,
    "camden-baskets-set-3": `${texxture}/products/camden-baskets`,
    "cassini-leaves-set-of-10": `${texxture}/products/cassini-leaves`,
    "catanga-riser-large": `${texxture}/products/catanga-riser-large`,
    "catanga-riser-medium": `${texxture}/products/catanga-riser-medium`,
    "catanga-stool": `${texxture}/products/catanga-stool`,
    "catseye-candleholder-clear": `${texxture}/products/catseye`,
    "catseye-candleholder-white-frost": `${texxture}/products/catseye`,
    "cheers-coasters-set-10": `${di}/products/cheers-coasters-set-10`,
    "chiku-bowl-teardrop": `${texxture}/products/chiku-bowl-teardrop`,
    "chiku-fork": `${texxture}/products/chiku-fork`,
    "chiku-platter-serving-tray": `${texxture}/products/chiku-serving-tray`,
    "chiku-platter-thin-rectangle": `${texxture}/products/chiku-platter`,
    "chiku-platter-thumb-cavity": `${texxture}/products/chiku-platter-w-thumb-cavity`,
    "chiku-platter-with-handle": `${texxture}/products/chiku-platter-w-handle`,
    "clara-advent-calendar": `${texxture}/products/clara-advent-calendar`,
    "clearcup-tealights-box-of-12white": `${texxture}/products/tealights-set-small`,
    "clearcup-tealights-box-of-50white": `${texxture}/products/tealights-set-large`,
    "clip-candleholder": `${texxture}/products/clip-candleholder-expo`,
    "clipnote-organizer-black": `${di}/products/clipnote`,
    "clipnote-organizer-brass": `${di}/products/clipnote`,
    "clipnote-organizer-silver": `${di}/products/clipnote`,
    "coinkeeper-keychain-holder-blue": `${di}/products/coinkeeper-keychain`,
    "coinkeeper-keychain-holder-pink": `${di}/products/coinkeeper-keychain`,
    "controlz-eraser": `${di}/products/controlz-eraser`,
    "copenhagen-bench-banana-bark": `${texxture}/products/copenhagen-bench`,
    "copenhagen-chair": `${texxture}/products/copenhagen-chair-banana`,
    "copenhagen-chair-leather": `${texxture}/products/copenhagen-chair-leather`,
    "copenhagen-ottoman-banana-bark": `${texxture}/products/copenhagen-ottoman-bananna`,
    "copenhagen-ottoman-leather": `${texxture}/products/copenhagen-ottoman-leather`,
    "crew-votive-cup-assorted": `${texxture}/products/`,
    "deseo-bird": `${texxture}/products/deseo-bird`,
    "desktopcroquet-set": `${di}/products/desktopcroquet-set`,
    "doodles-bicycle-pastel-asst": `${di}/products/doodles-bicycle-pastel-asst`,
    "doodles-ornament-tree": `${texxture}/products/doodles-ornament-brass-tree`,
    "doodles-ornament-wreath": `${texxture}/products/doodles-ornament-brass-wreath`,
    "doodles-tandem-bicycle": `${di}/products/doodles-tandem-bicycle`,
    "easterly-decorations-bunnies-set-8": `${texxture}/products/easterly-decor`,
    "easterly-decorations-set-of-12": `${texxture}/products/easterly-decorations`,
    "edison-drawerstore-organizer-3x12": `${di}/products/edison-drawer-store-3x12`,
    "edison-drawerstore-organizer-3x6": `${di}/products/edison-drawer-store-3x6`,
    "edison-drawerstore-organizer-3x9": `${di}/products/edison-drawer-store-3x9`,
    "edison-drawerstore-organizer-6x12": `${di}/products/edison-drawer-store-6x12`,
    "edison-drawerstore-organizer-6x6": `${di}/products/edison-drawer-store-6x6`,
    "edison-drawerstore-organizer-6x9": `${di}/products/edison-drawer-store-6x9`,
    "edison-storage-nest-large": `${di}/products/edison-storage-nest-lg`,
    "edison-storage-nest-small": `${di}/products/edison-storage-nest-sm`,
    "edison-utensil-cup-small": `${di}/products/edison-utensil-cup`,
    "einstein-eraser": `${di}/products/einstein-eraser`,
    "einstein-pen": `${di}/products/einstein-pen`,
    "elemental-nativity-set-of-4": `${texxture}/products/elemental-nativity`,
    "endra-champagne-flutes-6-oz-set-of-4": `${texxture}/products/endra-chmpagne-flutes`,
    "endra-drinking-glass-11-oz": `${texxture}/products/endra-drinking-glass-short`,
    "endra-drinking-glass-16-oz": `${texxture}/products/endra-drinking-glass-tall`,
    "eos-candleholder-set-5-black": `${texxture}/products/eos-candleholders`,
    "epilogue-bookends-set-of-2": `${di}/products/epilogue-bookends-set-2`,
    "erasercity-set-of-3new-york": `${di}/products/erasercity-set-3-newyork`,
    "fable-set": `${di}/products/fable-set`,
    "fake-stamp": `${di}/products/fake-stamp`,
    "fashionista-photoclips": `${di}/products/fashionista-photoclips`,
    "festive-ornament-3d-angel": `${texxture}/products/festive-ornament-angels`,
    "festive-ornament-3d-bauble": `${texxture}/products/festive-ornament-round`,
    "festive-ornament-3d-finial": `${texxture}/products/festive-ornament`,
    "festive-ornament-3d-penguin": `${texxture}/products/festive-ornament-3d-penguin`,
    "festive-ornament-bulb": `${texxture}/products/festive-ornament-bulb`,
    "festive-ornament-house": `${texxture}/products/festive-ornament-house`,
    "festive-ornament-stocking": `${texxture}/products/festive-ornament-stocking`,
    "festive-ornament-train": `${texxture}/products/festive-ornament-train`,
    "fireescape-shelf": `${texxture}/products/fireescape`,
    "fish-bottle-opener": `${texxture}/products/fish-bottle-opener-expo`,
    "fixit-pencil-sharpener-red": `${di}/products/fixit-pencil-sharpener`,
    "flameless-tealights-set-of-6": `${texxture}/products/flameless-tealights`,
    "fleet-pushpins-gray": `${di}/products/fleet-pushpins-set-6`,
    "flexket-basket-largeorange": `${di}/products/flexket-basket`,
    "flurry-paper-snowflake-large-crystal": `${texxture}/products/flurry-snowflake-crystal-large`,
    "flurry-paper-snowflake-medium-arctic": `${texxture}/products/flurry-snowflake-arctic-medium`,
    "flurry-paper-snowflake-medium-dazzle": `${texxture}/products/flurry-snowflake-dazzle-medium`,
    "flurry-paper-snowflake-small-marquise": `${texxture}/products/flurry-snowflake-marquise-small`,
    "flurry-paper-snowflake-small-tinsel": `${texxture}/products/flurry-snowflake-tinsel-small`,
    "focus-cloth-crossword-puzzle": `${di}/products/focus-cloth-crossword-puzzle`,
    "focus-cloth-donut": `${di}/products/focus-cloth-circular-donut`,
    "focus-cloth-eyechart1": `${di}/products/focus-cloth-eyechart`,
    "focus-cloth-floral": `${di}/products/focuscloth-floral`,
    "focus-cloth-mona-lisa": `${di}/products/focuscloth-pixelated-mona-lisa`,
    "focus-cloth-nyc": `${di}/products/focus-cloth-nyc`,
    "focus-cloth-pizza": `${di}/products/focus-cloth-circular-pizza`,
    "folio-pouch-large-terrazza": `${di}/products/folio-pouch-terrazzo-large`,
    "folio-pouch-medium-terrazza": `${di}/products/folio-terrazzo-medium`,
    "folio-pouch-tablet-terrazza": `${di}/products/folio-pouch-terrazzo-tablet`,
    "framework-plant-riser": `${texxture}/products/framework-plant-riser`,
    "franklin-corner-riser": `${di}/products/franklin-corner-riser`,
    "franklin-desk-riser-large": `${di}/products/franklin-desk-riser-lg`,
    "franklin-desk-riser-small": `${di}/products/franklin-desk-riser-sm`,
    "gabrielle-the-angel-decoration-large": `${texxture}/products/gabrielle-the-angel-large`,
    "gabrielle-the-angel-decoration-medium": `${texxture}/products/gabrielle-the-angel-medium`,
    "gabrielle-the-angel-decoration-small": `${texxture}/products/gabrielle-the-angel-small`,
    "gabrielle-the-angel-decorations-set-6": `${texxture}/products/gabrielle-the-angel`,
    "gabrielle-the-angel-ornament": `${texxture}/products/gabrielle-the-angel-ornament`,
    "gandhi-pen": `${di}/products/gandhi-pen`,
    "gelgems-large-bag-arrow-of-love": `${di}/products/gelgems-bag-lg-arrow-of-love`,
    "gelgems-large-bag-autumn-trees": `${di}/products/gelgems-bag-lg-autumn-trees`,
    "gelgems-large-bag-balloon-ride": `${di}/products/gelgems-bag-lg-balloon-rides`,
    "gelgems-large-bag-buggy-for-you": `${di}/products/gelgems-bag-lg-buggy-for-you`,
    "gelgems-large-bag-crayon-flower-egg": `${di}/products/gelgems-bag-lg-crayonfloweregg`,
    "gelgems-large-bag-crayons": `${di}/products/gelgems-bag-sm-crayons`,
    "gelgems-large-bag-creepy-crawlers": `${di}/products/gelgems-bag-lg-creepy-crawlers`,
    "gelgems-large-bag-daisy-tulips": `${di}/products/gelgems-bag-lg-daisies-tulips`,
    "gelgems-large-bag-dandelions": `${di}/products/gelgems-bag-lg-dandelions`,
    "gelgems-large-bag-ducks-bucks": `${di}/products/gelgems-bag-lg-ducks-bucks`,
    "gelgems-large-bag-end-of-the-rainbow": `${di}/products/gelgems-bag-sm-endoftherainbow`,
    "gelgems-large-bag-festive-gnomes": `${di}/products/gelgems-bag-lg-festive-omes`,
    "gelgems-large-bag-forest-valentine": `${di}/products/gelgems-bag-lg-forest-valentne`,
    "gelgems-large-bag-foxy": `${di}/products/gelgems-bag-sm-foxy`,
    "gelgems-large-bag-frog-princess": `${di}/products/gelgems-bag-lg-frog-princess`,
    "gelgems-large-bag-frozen-friends": `${di}/products/gelgems-bag-lg-frozen-friends`,
    "gelgems-large-bag-funky-easter": `${di}/products/gelgems-bag-lg-funky-easter`,
    "gelgems-large-bag-ghostly-tricks": `${di}/products/gelgems-bag-lg-ghostly-tricks`,
    "gelgems-large-bag-give-thanks": `${di}/products/gelgems-bag-sm-give-thanks`,
    "gelgems-large-bag-glow-pumpkins": `${di}/products/gelgems-bag-lg-glow-pumpkins`,
    "gelgems-large-bag-happy-valentines-day": `${di}/products/gelgems-bag-sm-love-banner`,
    "gelgems-large-bag-heart-flowers": `${di}/products/gelgems-bag-lg-heart-flowers`,
    "gelgems-large-bag-jungle-hearts": `${di}/products/gelgems-bag-lg-jungle-hearts`,
    "gelgems-large-bag-layered-hearts": `${di}/products/gelgems-bag-lg-layered-hearts`,
    "gelgems-large-bag-leaping-bunnies": `${di}/products/gelgems-bag-lg-leaping-bunnies`,
    "gelgems-large-bag-little-bots": `${di}/products/gelgems-bag-lg-little-bots`,
    "gelgems-large-bag-little-chicks": `${di}/products/gelgems-bag-lg-little-chicks`,
    "gelgems-large-bag-love-bots": `${di}/products/gelgems-bag-lg-love-bots`,
    "gelgems-large-bag-love-tweets": `${di}/products/gelgems-bag-lg-love-tweets`,
    "gelgems-large-bag-manly-hearts": `${di}/products/gelgems-bag-sm-manly-hearts`,
    "gelgems-large-bag-mr-and-mrs-funny-bunny": `${di}/products/gelgems-bag-lg-mrmrsfunnybunny`,
    "gelgems-large-bag-mr-funny-bunny": `${di}/products/gelgems-bag-lg-mr-funny-bunny`,
    "gelgems-large-bag-ombre-easter": `${di}/products/gelgems-bag-lg-ombre-easter`,
    "gelgems-large-bag-painted-skulls": `${di}/products/gelgems-bag-lg-painted-skulls`,
    "gelgems-large-bag-paper-airplanes": `${di}/products/gelgems-bag-sm-paper-airplanes`,
    "gelgems-large-bag-petal-patch": `${di}/products/gelgems-bag-sm-petal-patch`,
    "gelgems-large-bag-pink-blossoms": `${di}/products/gelgems-bag-sm-blossoms`,
    "gelgems-large-bag-potted-flowers": `${di}/products/gelgems-bag-lg-potted-flowers`,
    "gelgems-large-bag-pretty-eggs": `${di}/products/gelgems-bag-lg-pretty-eggs`,
    "gelgems-large-bag-rabbits-eggs": `${di}/products/gelgems-bag-lg-rabbits-eggs`,
    "gelgems-large-bag-retro-flowers": `${di}/products/gelgems-bag-lg-retro-flowers`,
    "gelgems-large-bag-safari-valentine": `${di}/products/gelgems-bag-lg-safarivalentine`,
    "gelgems-large-bag-sketched-flowers": `${di}/products/gelgems-bag-lg-sketched-flower`,
    "gelgems-large-bag-squirrely": `${di}/products/gelgems-bag-lg-squirrely`,
    "gelgems-large-bag-start-your-engine": `${di}/products/gelgems-bag-lg-startyourengine`,
    "gelgems-large-bag-zig-zag-flowers": `${di}/products/gelgems-bag-lg-zigzag-flowers`,
    "gelgems-long-happy-valentines-day": `${di}/products/gelgems-bag-long-happy-val-day`,
    "gelgems-small-bag-at-sea": `${di}/products/gelgems-bag-sm-at-sea`,
    "gelgems-small-bag-autumn-days": `${di}/products/gelgems-bag-sm-autumn-days`,
    "gelgems-small-bag-beary-scary": `${di}/products/gelgems-bag-sm-beary-scary`,
    "gelgems-small-bag-blooms": `${di}/products/gelgems-bag-sm-blooms`,
    "gelgems-small-bag-circus-act": `${di}/products/gelgems-bag-sm-circus-act`,
    "gelgems-small-bag-delightful-daisies": `${di}/products/gelgems-bag-sm-delightfuldaisi`,
    "gelgems-small-bag-fancy-chicks": `${di}/products/gelgems-bag-sm-fancy-chicks`,
    "gelgems-small-bag-forest-pals": `${di}/products/gelgems-bag-sm-forest-pals`,
    "gelgems-small-bag-funky-chicks": `${di}/products/gelgems-bag-sm-funky-chicks`,
    "gelgems-small-bag-gingervillage-friends": `${di}/products/gelgems-bag-sm-gingrvillfriend`,
    "gelgems-small-bag-groovy-flowers": `${di}/products/gelgems-bag-sm-groovy-flowers`,
    "gelgems-small-bag-hoppy-easter": `${di}/products/gelgems-bag-sm-hoppy-easter`,
    "gelgems-small-bag-land-lake-creatures": `${di}/products/gelgems-bag-sm-land-lakecreatr`,
    "gelgems-small-bag-little-lamb": `${di}/products/gelgems-bag-sm-little-lamb`,
    "gelgems-small-bag-love": `${di}/products/gelgems-bag-sm-love`,
    "gelgems-small-bag-love-blooms": `${di}/products/gelgems-bag-sm-love-blooms`,
    "gelgems-small-bag-mums": `${di}/products/gelgems-bag-sm-mums`,
    "gelgems-small-bag-noel": `${di}/products/gelgems-bag-sm-noel`,
    "gelgems-small-bag-pastel-eggs": `${di}/products/gelgems-bag-sm-pastel-eggs`,
    "gelgems-small-bag-pattern-of-my-heart": `${di}/products/gelgems-bag-sm-pattrn-my-heart`,
    "gelgems-small-bag-peace-love": `${di}/products/gelgems-bag-sm-peace-love`,
    "gelgems-small-bag-pinwheels": `${di}/products/gelgems-bag-sm-pinwheels`,
    "gelgems-small-bag-potted-plants": `${di}/products/gelgems-bag-sm-potted-plants`,
    "gelgems-small-bag-swak": `${di}/products/gelgems-bag-sm-swak`,
    "gelgems-small-bag-tiny-dancers": `${di}/products/gelgems-bag-sm-tiny-dancers`,
    "gelgems-small-bag-tulips": `${di}/products/gelgems-bag-sm-tulips`,
    "gelgems-small-bag-witchy-owls": `${di}/products/gelgems-bag-sm-witchy-owls`,
    "gelgems-small-colorful-bunnies": `${di}/products/gelgems-bag-sm-colorfulbunnies`,
    "gelgems-small-comic-words": `${di}/products/gelgems-bag-sm-comic-words`,
    "gelgems-small-elegant-elephants": `${di}/products/gelgems-bag-sm-elegantelephant`,
    "gelgems-small-fashion-flowers": `${di}/products/gelgems-bag-sm-fashion-flowers`,
    "gelgems-small-heart-you-too": `${di}/products/gelgems-bag-sm-heart-you-too`,
    "gelgems-small-hipster": `${di}/products/gelgems-bag-sm-hipster`,
    "gelgems-small-monster-mash": `${di}/products/gelgems-bag-sm-monster-mash`,
    "gelgems-small-outline-flowers": `${di}/products/gelgems-bag-sm-outline-flowers`,
    "gelgems-small-patterned-eggs": `${di}/products/gelgems-bag-sm-patterned-eggs`,
    "gelgems-small-playful-pandas": `${di}/products/gelgems-bag-sm-playful-pandas`,
    "gelgems-small-silly-birds": `${di}/products/gelgems-bag-smly-birds`,
    "gelgems-small-tropical-flowers": `${di}/products/gelgems-bag-sm-tropical-flower`,
    "gelwonder-large-bag-blooming-bunnies": `${di}/products/gelwonder-lg-blooming-bunnies`,
    "gelwonder-large-bag-blue-christmas": `${di}/products/gelwonder-lg-christmas`,
    "gelwonder-large-bag-bunny-trail": `${di}/products/gelwonder-bag-lg-bunny-trail`,
    "gelwonder-large-bag-convo-hearts": `${di}/products/gelwonder-lg-convo-hearts`,
    "gelwonder-large-bag-dancin-bones": `${di}/products/gelwonder-lg-dancin-bones`,
    "gelwonder-large-bag-ice-princess": `${di}/products/gelwonder-bag-lg-ice-princess`,
    "gelwonder-large-bag-icy-flakes": `${di}/products/gelwonder-lg-icy-flakes`,
    "gelwonder-large-bag-jungle-cats": `${di}/products/gelwonder-lg-jungle-cats`,
    "gelwonder-large-bag-little-elves": `${di}/products/gelwonder-lg-little-elves`,
    "gelwonder-large-bag-love-letters": `${di}/products/gelwonder-lg-love-letters`,
    "gelwonder-large-bag-magical-kingdom": `${di}/products/gelwonder-bag-lg-magicalkingdm`,
    "gelwonder-large-bag-mod-deer": `${di}/products/gelwonder-bag-lg-mod-deer`,
    "gelwonder-large-bag-nordic-santas": `${di}/products/gelwonder-lg-nordic-santas`,
    "gelwonder-large-bag-rainbow-flower": `${di}/products/gelwonder-bag-lg-rainbowflower`,
    "gelwonder-large-bag-snazzy-santas": `${di}/products/gelwonder-lg-snazzy-santas`,
    "gelwonder-large-bag-southern-bouquet": `${di}/products/gelwonder-lg-southern-bouquet`,
    "gelwonder-large-bag-super-heroes": `${di}/products/gelwonder-bag-lg-super-heroes`,
    "gelwonder-large-bag-woodland-christmas": `${di}/products/gelwonder-bag-lg-woodlndchstms`,
    "gelwonder-small-bag-butterfly-garden": `${di}/products/gelwonder-bag-sm-butterflygrdn`,
    "gelwonder-small-bag-colorful-daisies": `${di}/products/gelwonder-sm-colorful-daisies`,
    "gelwonder-small-bag-dainty-vase": `${di}/products/gelwonder-bag-sm-dainty-vase`,
    "gelwonder-small-bag-frosty-penguins": `${di}/products/gelwonder-sm-frosty-penguins`,
    "gelwonder-small-bag-magical-creatures": `${di}/products/gelwonder-bag-sm-magiclcreatur`,
    "gelwonder-small-bag-ninjas": `${di}/products/gelwonder-sm-ninjas`,
    "gelwonder-small-bag-oh-dear": `${di}/products/gelwonder-bag-sm-oh-dear`,
    "gelwonder-small-bag-patterned-bunnies": `${di}/products/gelwonder-sm-patterned-bunnies`,
    "gelwonder-small-bag-playful-pups": `${di}/products/gelwonder-sm-playful-pups`,
    "gelwonder-small-bag-rose-garden": `${di}/products/gelwonder-sm-rose-garden`,
    "gelwonder-small-bag-valentines-banner": `${di}/products/gelwonder-sm-valentines-banner`,
    "gimbel-ornaments-set-5-gingerbread": `${texxture}/products/gimbel-ornaments-gingerbread`,
    "gimbel-ornaments-set-5-ugly-sweaters": `${texxture}/products/gimbel-ornaments-sweaters`,
    "gimbel-ornaments-set-5-vintage-glass": `${texxture}/products/gimbel-ornaments`,
    "greenwich-candleholder-chambray-short": `${texxture}/products/greenwich-candle-holder-short`,
    "greenwich-candleholder-chambray-tall": `${texxture}/products/greenwich-candle-holder-medium`,
    "greenwich-candleholder-herringbone-short": `${texxture}/products/greenwich-candle-holder-herringbone`,
    "greenwich-candleholder-herringbone-tall": `${texxture}/products/greenwich-candle-holder-large`,
    "henry-the-nutcracker-ornament": `${texxture}/products/henry-the-nutcracker-3d-ornmnt`,
    "heybuster-photo-holder": `${di}/products/heybuster-photo-holder`,
    "hidden-bookends-set-2": `${di}/products/hidden-bookends-set-2`,
    "hildgrim-cheese-knives-set-of-3": `${texxture}/products/hildgrim-cheese-knives`,
    "hollyjolly-mistletoe": `${texxture}/products/hollyjolly-ornament-mistletoe`,
    "hollyjolly-ornament-hot-cocoa": `${texxture}/products/hollyjolly-ornament-hot-cocoa`,
    "hollyjolly-ornament-ice-skate": `${texxture}/products/hollyjolly-ornament-ice-skate`,
    "hollyjolly-ornament-mrs.-claus": `${texxture}/products/hollyjolly-ornament-mrs-claus`,
    "hollyjolly-ornament-nutcracker": `${texxture}/products/hollyjolly-ornament-nutcracker`,
    "hollyjolly-ornament-poinsettia": `${texxture}/products/hollyjolly-ornament-poinsettia`,
    "hollyjolly-ornament-rudolph": `${texxture}/products/hollyjolly-ornament-reindeer`,
    "hollyjolly-ornament-santa": `${texxture}/products/hollyjolly-ornament-santa`,
    "hollyjolly-ornament-snowflake": `${texxture}/products/hollyjolly-ornament-snowflake`,
    "hollyjolly-ornament-stocking": `${texxture}/products/hollyjolly-ornament-stocking`,
    "hollyjolly-ornament-tree": `${texxture}/products/hollyjolly-ornament-tree`,
    "hollyjolly-ornament-tree-farm": `${texxture}/products/hollyjolly-ornament-tree-farm`,
    "hollyjolly-ornament-winter-hat": `${texxture}/products/hollyjolly-ornament-winter-hat`,
    "hollyjolly-ornament-wreath": `${texxture}/products/hollyjolly-ornament-simple-wreath`,
    "hollyjolly-wreath": `${texxture}/products/hollyjolly-ornament-wreath`,
    "hookover-hook-black": `${di}/products/hookover`,
    "hookover-hook-white": `${di}/products/hookover`,
    "hudson-decoration-large": `${texxture}/products/hudson-decoration`,
    "hudson-ornament": `${texxture}/products/hudson-ornament`,
    "identitycase-holder-circuitwhite": `${di}/products/identity-case-circuit`,
    "identitycase-holder-give-taketake": `${di}/products/identity-case-give-and-take`,
    "identitycase-holder-glimmer": `${di}/products/identitycase-glimmer`,
    "identitycase-holder-marble": `${di}/products/identity-case`,
    "identitycase-holder-mona-lisa": `${di}/products/identity-case-mona-lisa`,
    "identitycase-holder-terrazza": `${di}/products/identity-case-terrazzo`,
    "irawaddy-basket-set-of-2": `${texxture}/products/irawaddy-backet-set`,
    "jasper-candelabra": `${texxture}/products/jasper-wire-candelabra`,
    "jasper-taper-holder-12.4-in": `${texxture}/products/jasper-taper-holder-round`,
    "jasper-taper-holder-9.8-in": `${texxture}/products/jasper-taper-holder-bucket`,
    // "jasper-taper-holder-9.8-in": `${texxture}/products/jasper-taper-holder-cone`,
    "jasper-taper-strand-4-tapers": `${texxture}/products/jasper-wire-taper-hldr`,
    "jasper-taper-wreath": `${texxture}/products/jasper-wire-taper-wreath`,
    "jasper-tealight-wreath": `${texxture}/products/jasper-tealight-wreath`,
    "java-drawer-organizer-3-x-6": `${texxture}/products/java-drawer-organizer-3x6`,
    "java-drawer-organizer-6-x-12": `${texxture}/products/java-drawer-organizer-6x12`,
    "java-drawer-organizer-6-x-6": `${texxture}/products/java-drawer-organizer-6x6`,
    "java-drawer-organizer-6-x-9": `${texxture}/products/java-drawer-organizer-6x9`,
    "kamala-lounge-chair": `${texxture}/products/kamala-lounge-chair`,
    "lacrate-tote-largewhite": `${di}/products/lacrate-large`,
    "lacrate-tote-smallwhite": `${di}/products/lacrate-small`,
    "ladyliberty-eraser": `${di}/products/ladyliberty-eraser`,
    "lampang-bowl": `${texxture}/products/lampang-bowl`,
    "lampang-coasters-set-of-4": `${texxture}/products/lampang-coasters`,
    "lampang-platter-12.2-x-7.3-x-1.5-in": `${texxture}/products/lampang-platter-small`,
    "lampang-platter-14.7-x-8.1-x-1.1-in": `${texxture}/products/lampang-platter-medium`,
    "lampang-platter-17.7-x-5.7-x-1.0-in": `${texxture}/products/lampang-platter-long`,
    "lampang-platter-20.4-x-11.6-x-1.4-in": `${texxture}/products/lampang-platter-large`,
    "larsen-lounge-chair-golden": `${texxture}/products/larsen-lounge-chair`,
    "larsen-lounge-chair-natural": `${texxture}/products/larsen-lounge-chair`,
    "lexington-drinkware-double-wall-mug": `${texxture}/products/lexington-drinkware-mug`,
    "lexington-drinkware-double-wall-pint-glass": `${texxture}/products/lexington-drinkware-dw`,
    "lexington-drinkware-juice-glass": `${texxture}/products/lexington-drinkware-juice`,
    "lexington-drinkware-old-fashioned-tumbler": `${texxture}/products/lexington-drinkware-old-fashion`,
    "lexington-drinkware-pint-glass": `${texxture}/products/lexington-drinkware-sw`,
    "liana-lantern": `${texxture}/products/liana-lantern`,
    "liana-rectangular-box-large": `${texxture}/products/liana-rectangular-box-large`,
    "liana-rectangular-box-small": `${texxture}/products/liana-rectangular-box`,
    "liana-rectangular-placemat": `${texxture}/products/liana-placemat-rectangle`,
    "liana-rectangular-tray": `${texxture}/products/liana-rectangular-tray`,
    "liana-round-placemat": `${texxture}/products/liana-placemat-round`,
    "liana-round-serving-tray": `${texxture}/products/liana-serving-tray-roud`,
    "liana-round-tray": `${texxture}/products/liana-round-tray`,
    "liana-serving-tray": `${texxture}/products/liana-serving-tray-rectangle`,
    "liana-storage-basket": `${texxture}/products/liana-storage-basket`,
    "liana-umbrella-basket": `${texxture}/products/liana-umbrella-basket`,
    "lifechamp-magnet-binge-watching-marathon": `${di}/products/lifechamp-maet-bingewatchmar`,
    "lightlight-votives-set-of-4white": `${texxture}/products/lightlight-votive`,
    "lilas-bistro-chair-set-of-2": `${texxture}/products/lilas-bistro-chair`,
    "lookers-boxes-squareextra-largeclear": `${di}/products/lookers-box-sq-xlarge`,
    "lookers-boxes-squarelargeclear": `${di}/products/lookers-box-sq-large`,
    "lookers-boxes-squaremediumclear": `${di}/products/lookers-box-sq-medium`,
    "lookers-photomobile": `${di}/products/lookers-photomobile`,
    "lotus-chair": `${texxture}/products/lotus-chair`,
    "luna-photoclips-white": `${di}/products/luna-photoclips`,
    "maddox-tape-measure-cube": `${texxture}/products/maddox-tape-measure-cube`,
    "maddox-tape-measure-pyramid": `${texxture}/products/maddox-tape-measure-pyramid`,
    "maddox-tape-measure-sphere": `${texxture}/products/maddox-tape-measure-sphere`,
    "magicactus-kit-blue": `${di}/products/magicactus-kit`,
    "magicactus-kit-green": `${di}/products/magicactus-kit`,
    "magicactus-kit-orange": `${di}/products/magicactus-kit`,
    "magicactus-kit-pink": `${di}/products/magicactus-kit`,
    "maiz-basket-large-handles": `${texxture}/products/maiz-basket-w-handles-large`,
    "maiz-basket-medium-handles": `${texxture}/products/maiz-basket-w-handles-medium`,
    "maiz-basket-small-handles": `${texxture}/products/maiz-basket-w-handles-small`,
    "maiz-placemat": `${texxture}/products/maiz-placemat`,
    "maiz-throw-basket": `${texxture}/products/maiz-throw-basket`,
    "maiz-wastecan": `${texxture}/products/maiz-wastecan`,
    "malmo-vase-4.7-x-4.3-x-4.5-in": `${texxture}/products/malmo-vase`,
    "malmo-vase-6.6-x-6.7-in": `${texxture}/products/malmo-vase-clear`,
    "manistee-candle-holder-10.5-inches": `${texxture}/products/manistee-candle-holder-large`,
    "manistee-candle-holder-8.75-inches": `${texxture}/products/manistee-candle-holder-small`,
    "mapdeck-playing-cards-nyc": `${di}/products/mapdeck-nyc`,
    "mapkin-napkins-set-of-20nyc": `${di}/products/mapkin-5x5g-20-newyorkcity`,
    "marari-hemp-placemat-bleached": `${texxture}/products/marari-placemat`,
    "marari-hemp-placemat-gray": `${texxture}/products/marari-placemat`,
    "marari-hemp-placemat-natural": `${texxture}/products/mancha-cheese-knife`,
    // "marari-hemp-placemat-natural": `${texxture}/products/marari-placemat`,
    "mary-pen-assorted": `${di}/products/mary-pen-asst`,
    "mason-vase-medium": `${texxture}/products/mason-vase-medium`,
    "mason-vase-set-asst-set-8": `${texxture}/products/mason-vase-set`,
    "mason-vase-skinny": `${texxture}/products/mason-vase-skinny`,
    "melia-basket-black-12-x-9.5-x-3-in": `${texxture}/products/melia-basket-tray`,
    "melia-basket-black-14.8-dia-x-12.2-in": `${texxture}/products/melia-basket`,
    "melia-basket-sage-12-x-9.5-x-3-in": `${texxture}/products/melia-basket-tray`,
    "melia-basket-sage-14.8-dia-x-12.2-in": `${texxture}/products/melia-basket`,
    "melia-basket-sand-12-x-9.5-x-3-in": `${texxture}/products/melia-basket-tray`,
    "melia-basket-sand-14.8-dia-x-12.2-in": `${texxture}/products/melia-basket`,
    "melia-basket-slate-12-x-9.5-x-3-in": `${texxture}/products/melia-basket-tray`,
    "melia-basket-slate-14.8-dia-x-12.2-in": `${texxture}/products/melia-basket`,
    "melia-coasters-set-4-black": `${texxture}/products/melia-coasters`,
    "melia-coasters-set-4-sand": `${texxture}/products/melia-coasters`,
    "melia-coasters-set-4-slate": `${texxture}/products/melia-coasters`,
    "melia-coasters-set-of-4-sage": `${texxture}/products/melia-coasters`,
    "melia-containers-set-4-black": `${texxture}/products/melia-cachepot-set-4`,
    "melia-containers-set-4-sand": `${texxture}/products/melia-cachepot-set-4`,
    "melia-containers-set-4-slate": `${texxture}/products/melia-cachepot-set-4`,
    "melia-hanging-basket-black-4.6-x-5.2-x-4.8-in": `${texxture}/products/melia-hngbskt`,
    "melia-hanging-basket-black-6.3-x-7-x-6.5-in": `${texxture}/products/melia-hng-bskt`,
    "melia-hanging-basket-sage-4.6-x-5.2-x-4.8-in": `${texxture}/products/melia-hngbskt`,
    "melia-hanging-basket-sage-6.3-x-7-x-6.5-in": `${texxture}/products/melia-hng-bskt`,
    "melia-hanging-basket-sand-4.6-x-5.2-x-4.8-in": `${texxture}/products/melia-hngbskt`,
    "melia-hanging-basket-sand-6.3-x-7-x-6.5-in": `${texxture}/products/melia-hng-bskt`,
    "melia-hanging-basket-slate-4.6-x-5.2-x-4.8-in": `${texxture}/products/melia-hngbskt`,
    "melia-hanging-basket-slate-6.3-x-7-x-6.5-in": `${texxture}/products/melia-hng-bskt`,
    "melia-pencil-cup-black": `${texxture}/products/melia-pencil-cup`,
    "melia-pencil-cup-sage": `${texxture}/products/melia-pencil-cup`,
    "melia-pencil-cup-sand": `${texxture}/products/melia-pencil-cup`,
    "melia-pencil-cup-slate": `${texxture}/products/melia-pencil-cup`,
    "melia-placemat-black": `${texxture}/products/melia-placemat`,
    "melia-placemat-sage": `${texxture}/products/melia-placemat`,
    "melia-placemat-sand": `${texxture}/products/melia-placemat`,
    "melia-placemat-slate": `${texxture}/products/melia-placemat`,
    "melia-pouf-narrow-black": `${texxture}/products/melia-pouf-tall`,
    "melia-pouf-narrow-sand": `${texxture}/products/melia-pouf-tall`,
    "melia-pouf-wide-black": `${texxture}/products/melia-pouf-short`,
    "melia-pouf-wide-sand": `${texxture}/products/melia-pouf-short`,
    "melia-wastecan-black": `${texxture}/products/melia-wastecan`,
    "melia-wastecan-sage": `${texxture}/products/melia-wastecan`,
    "melia-wastecan-sand": `${texxture}/products/melia-wastecan`,
    "melia-wastecan-slate": `${texxture}/products/melia-wastecan`,
    "mesabi-bowl-6-in": `${texxture}/products/mesabi-bowl`,
    "mesabi-bowls-set-of-3-4-in": `${texxture}/products/mesabi-bowl-set`,
    "mesh-bookends-set-of-2": `${di}/products/bookends-mesh-pair`,
    "mesh-business-card-holder-black": `${di}/products/meshworks-business-card-holder`,
    "mesh-cabinet-baskets-silver": `${di}/products/cabinet-baskets-mesh`,
    "mesh-cd-box-silver": `${di}/products/meshworks-r-stacking-bin-5-7x11`,
    "mesh-cutlery-tray-largesilver": `${di}/products/cutlery-tray-mesh-large`,
    "mesh-cutlery-tray-smallsilver": `${di}/products/cutlery-tray-mesh-small`,
    "mesh-desk-buddy": `${di}/products/desk-buddy-mesh`,
    "meshdrawerstore-organizer-3x12": `${di}/products/drawer-store-mesh-3-x-12`,
    "meshdrawerstore-organizer-3x3": `${di}/products/drawer-store-mesh-3-x-3`,
    "meshdrawerstore-organizer-3x6": `${di}/products/drawer-store-mesh-3-x-6`,
    "meshdrawerstore-organizer-3x9": `${di}/products/drawer-store-mesh-3-x-9`,
    "meshdrawerstore-organizer-6x12": `${di}/products/drawer-store-mesh-6-x-12`,
    "meshdrawerstore-organizer-6x6": `${di}/products/drawer-store-mesh-6-x-6`,
    "meshdrawerstore-organizer-6x9": `${di}/products/drawer-store-mesh-6-x-9`,
    "mesh-dvd-box-silver": `${di}/products/stacking-bin-mesh-8x14`,
    "mesh-file-box-silver": `${di}/products/file-box-mesh`,
    "mesh-letter-holder-black": `${di}/products/letter-holder-mesh`,
    "mesh-letter-holder-silver": `${di}/products/letter-holder-mesh`,
    "mesh-letter-tray-black": `${di}/products/letter-tray-mesh`,
    "mesh-letter-tray-silver": `${di}/products/letter-tray-mesh`,
    "mesh-magazine-file-black": `${di}/products/magazine-file-mesh`,
    "mesh-magazine-file-silver": `${di}/products/magazine-file-mesh`,
    "meshmagnet-bin-extra-largesilver": `${di}/products/maet-mesh-xlarge`,
    "meshmagnet-bin-largesilver": `${di}/products/maet-mesh-large`,
    "meshmagnet-bin-mediumsilver": `${di}/products/maet-mesh-medium`,
    "meshmagnet-bin-smallsilver": `${di}/products/maet-mesh-small`,
    "meshmagnet-organizer-extra-largesilver": `${di}/products/maet-organizer-mesh`,
    "mesh-office-manager-silver": `${di}/products/office-manager-mesh`,
    "mesh-pantry-caddy-silver": `${di}/products/pantry-caddy-mesh`,
    "mesh-pencil-cube-silver": `${di}/products/pencil-cube-mesh`,
    "mesh-pencil-cup-black": `${di}/products/pencil-cup-mesh`,
    "mesh-pencil-cup-giantsilver": `${di}/products/giant-pencil-cup-mesh`,
    "mesh-pencil-cup-silver": `${di}/products/pencil-cup-mesh`,
    "mesh-quartet-cup-silver": `${di}/products/quartet-cup-mesh`,
    "mesh-shelving-unit-3-tier-black": `${di}/products/meshworks-shelving-3-tier`,
    "mesh-shelving-unit-3-tier-petrol-blue": `${di}/products/meshworks-shelving-3-tier`,
    "mesh-shelving-unit-3-tier-silver": `${di}/products/meshworks-shelving-3-tier`,
    "mesh-shelving-unit-3-tier-white": `${di}/products/meshworks-shelving-3-tier`,
    "mesh-shelving-unit-5-tier-black": `${di}/products/meshworks-shelving-5-tier`,
    "mesh-shelving-unit-5-tier-silver": `${di}/products/meshworks-shelving-5-tier`,
    "mesh-shelving-unit-5-tier-white": `${di}/products/meshworks-shelving-5-tier`,
    "mesh-spice-stepper-silver": `${di}/products/spice-stepper-mesh`,
    "mesh-spinner-silver": `${di}/products/spinner-mesh`,
    "mesh-stacking-bin-4.5-x-8-white": `${di}/products/meshworks-r-stacking-bin-1`,
    "mesh-stacking-bin-5.5-x-12-white": `${di}/products/meshworks-r-stacking-bin`,
    "mesh-stacking-bin-5.7-x-11-white": `${di}/products/meshworks-r-stacking-bin-5-7x11`,
    "mesh-stacking-bin-silver-8-x-7": `${di}/products/meshworks-r-stacking-bin-2`,
    "mesh-stacking-bin-white-8-x-14": `${di}/products/stacking-bin-mesh-8x14`,
    "mesh-stacking-bin-white-8-x-7": `${di}/products/meshworks-r-stacking-bin-2`,
    "mesh-step-pockets-silver": `${di}/products/steppockets-mesh`,
    "mesh-step-sorter-silver": `${di}/products/stepsorter-mesh`,
    "mesh-storage-nest-extra-largesilver": `${di}/products/storage-nest-mesh-giant`,
    "mesh-storage-nest-extra-smallsilver": `${di}/products/storage-nest-mesh-xsmall`,
    "mesh-storage-nest-largesilver": `${di}/products/storage-nest-mesh-large`,
    "mesh-storage-nest-longsilver": `${di}/products/storage-nest-mesh-long`,
    "mesh-storage-nest-mesh-small": `${di}/products/storage-nest-mesh-small`,
    "mesh-stuff-shelf-largesilver": `${di}/products/stuffshelf-mesh-large`,
    "mesh-stuff-shelf-smallsilver": `${di}/products/stuffshelf-mesh-small`,
    "mesh-tabletop-file-silver": `${di}/products/meshworks-r-stacking-bin`,
    "mesh-undershelf-basket-largesilver": `${di}/products/undershelf-basket-mesh-lg`,
    "mesh-undershelf-basket-smallsilver": `${di}/products/undershelf-basket-mesh-sm`,
    "mesh-utensil-cup-silver": `${di}/products/utensil-cup-mesh`,
    "mesh-wall-works-3-pocketssmallsilver": `${di}/products/wall-pockets-mesh`,
    "mesh-wastecan-minisilver": `${di}/products/wastecan-mesh-mini`,
    "mesh-wastecan-minisilver1": `${di}/products/wastebin-mesh-mini`,
    "meshworks-beverage-dispenser-silver": `${di}/products/meshworks-beverage-disp`,
    "meshworks-beverage-dispenser-white": `${di}/products/meshworks-beverage-disp`,
    "meshworks-caster-wheels-set-4": `${di}/products/meshworks-locking-casters-st-4`,
    "meshworks-entryway-bench-white": `${di}/products/meshworks-entryway-bench`,
    "meshworks-locker": `${di}/products/meshworks-locker`,
    "meshworks-narrow-shelving-unit-6-tier-black": `${di}/products/meshworks-narrow-unit`,
    "meshworks-narrow-shelving-unit-6-tier-blush-pink": `${di}/products/meshworks-narrow-unit`,
    "meshworks-narrow-shelving-unit-6-tier-sage-green": `${di}/products/meshworks-narrow-unit`,
    "meshworks-narrow-shelving-unit-6-tier-silver": `${di}/products/meshworks-narrow-unit`,
    "meshworks-narrow-shelving-unit-6-tier-white": `${di}/products/meshworks-narrow-unit`,
    "meshworks-open-front-bin-white": `${di}/products/meshworks-open-front-bin`,
    "meshworks-shelving-unit-3-tier-sage-green": `${di}/products/meshworks-shelving-3-tier`,
    "meshworks-shelving-unit-5-tier-sage-green": `${di}/products/meshworks-shelving-5-tier`,
    "meshworks-shoe-shelf-black": `${di}/products/meshworks-shoe-shelf`,
    "meshworks-shoe-shelf-sage-green": `${di}/products/meshworks-shoe-shelf`,
    "meshworks-shoe-shelf-silver": `${di}/products/meshworks-shoe-shelf`,
    "meshworks-shoe-shelf-white": `${di}/products/meshworks-shoe-shelf`,
    "meshworks-tower-black": `${di}/products/meshworks-tower`,
    "meshworks-tower-sage": `${di}/products/meshworks-tower`,
    "meshworks-tower-silver": `${di}/products/meshworks-tower`,
    "meshworks-tower-white": `${di}/products/meshworks-tower`,
    "meshworks-utility-unit-black": `${di}/products/meshworks-utility-wood-top`,
    "meshworks-utility-unit-sage-green": `${di}/products/meshworks-utility-wood-top`,
    "meshworks-utility-unit-silver": `${di}/products/meshworks-utility-wood-top`,
    "meshworks-utility-unit-white": `${di}/products/meshworks-utility-wood-top`,
    "mesh-zip-box-silver": `${di}/products/meshworks-r-stacking-bin-1`,
    "miraval-riser-rectangletallgray": `${texxture}/products/miraval-riser`,
    "monochrome-gel-pen-black": `${di}/products/monochrome-gel-pens`,
    "monochrome-gel-pen-white": `${di}/products/monochrome-gel-pens`,
    "neo-wall-grid": `${texxture}/products/neo-wall-grid`,
    "nicolai-candleholder-extra-large": `${texxture}/products/nicolai-candleholder-large`,
    "no2-pushpins-set-of-6": `${di}/products/no2-pushpins-set-6`,
    "nordicelf-decorations-set-of-8": `${texxture}/products/nordicelf-decorations`,
    "nordic-ornament-finial": `${texxture}/products/nordic-ornament-finial`,
    "nordic-ornament-nutcracker": `${texxture}/products/nordic-ornament-nutcracker`,
    "nordic-ornament-skate": `${texxture}/products/nordic-ornament-skate`,
    "nordic-ornament-sleigh": `${texxture}/products/nordic-ornament-sleigh`,
    "nordicsanta-decoration-medium": `${texxture}/products/nordic-santa-large`,
    "nordicsanta-decorations-set-of-8": `${texxture}/products/nordic-santa-small`,
    "nordic-tealight-holder-extra-small": `${texxture}/products/nordic-tealight-holder`,
    "nordic-tealight-holder-medium": `${texxture}/products/nordic-tealight-holder-medium`,
    "nordic-tealight-holder-set-6": `${texxture}/products/nordic-tealight-holders`,
    "nordic-tealight-holder-small": `${texxture}/products/nordic-tealight-holder-small`,
    "nordic-tealight-trough": `${texxture}/products/nordic-tealight-trough`,
    "nordic-tree-6-ft": `${texxture}/products/nordic-tree-natrual-large`,
    "nordic-tree-extra-large": `${texxture}/products/nordic-tree-ex-large`,
    "nordic-tree-extra-small": `${texxture}/products/nordic-tree-ex-small`,
    "nordic-tree-large": `${texxture}/products/nordic-tree-large`,
    "nordic-tree-medium": `${texxture}/products/nordic-tree-medium`,
    "nordic-tree-small": `${texxture}/products/nordic-tree-small`,
    "nordland-tree-11-inches": `${texxture}/products/nordland-tree-large`,
    "nordland-tree-14-inches": `${texxture}/products/nordland-tree-ex-large`,
    "nordland-tree-6-inches": `${texxture}/products/nordland-tree-small`,
    "nordland-tree-8-inches": `${texxture}/products/nordland-tree-medium`,
    "officelife-award-master-of-bs": `${di}/products/office-life-award-master-of-bs`,
    "officelife-award-procrastiantor": `${di}/products/office-life-award-procrastinat`,
    "ormond-bar-stool": `${texxture}/products/ormond-bar-stool`,
    "ormond-counter-stool": `${texxture}/products/ormond-counter-stool`,
    "ormond-dining-chair": `${texxture}/products/ormond-dining-chair`,
    "ormond-lounge-chair": `${texxture}/products/ormond-lounge-chair`,
    "ormond-settee": `${texxture}/products/ormond-settee`,
    "ormond-side-chair": `${texxture}/products/ormond-side-chair`,
    "oslo-side-table": `${texxture}/products/oslo-side-table`,
    "pennant-magnets": `${di}/products/pennant-maets-set-90`,
    "pietra-coffee-table-teak1": `${texxture}/products/teak-pietra-coffee-table`,
    "pietra-console-table-teak": `${texxture}/products/teak-pietra-console-table`,
    "pietra-side-table-rectangle-brass-patina": `${texxture}/products/brass-pietra-side-table-recktangle`,
    "pietra-side-table-rectangle-cement": `${texxture}/products/cement-pietra-side-table-recktangle`,
    "pietra-side-table-rectangle-copper-patina": `${texxture}/products/copper-pietra-side-table-recktangle`,
    "pietra-side-table-rectangle-crushed-glass": `${texxture}/products/crushed-glass-pietra-side-table-recktangle`,
    "pietra-side-table-rectangle-teak": `${texxture}/products/teak-pietra-side-table-overhang`,
    "pietra-side-table-small-brass-patina": `${texxture}/products/brass-pietra-side-table-square`,
    "pietra-side-table-small-cement": `${texxture}/products/cement-pietra-side-table-square`,
    "pietra-side-table-small-copper-patina": `${texxture}/products/copper-pietra-side-table-square-1`,
    "pietra-side-table-small-crushed-glass": `${texxture}/products/crushed-glass-pietra-side-table-square`,
    "pietra-side-table-small-teak": `${texxture}/products/teak-pietra-side-table-square`,
    "pillar-candle-3x3white": `${texxture}/products/pillar-short`,
    "pillar-candle-3x4white": `${texxture}/products/pillar-medium`,
    "pillar-candle-3x6white": `${texxture}/products/pillar-tall`,
    "pillar-candle-3x9white": `${texxture}/products/pillar-ex-tall`,
    "pockit-wallet-cork": `${di}/products/pockit-cork`,
    "pockit-wallet-ripstopgray": `${di}/products/pockit`,
    "puzzleblocks-set-4-letter-words": `${di}/products/puzzleblocks-4-letter-words`,
    "puzzleblocks-set-nyc1": `${di}/products/puzzleblocks-nyc`,
    "puzzleblocks-set-terrazza": `${di}/products/puzzleblocks-set-4-terrazzo`,
    "pylon-pillar-candle-medium": `${texxture}/products/pylon-pillar-medium`,
    "pylon-pillar-candle-short": `${texxture}/products/pylon-pillar-short`,
    "pylon-pillar-candle-tall": `${texxture}/products/pylon-pillar-tall`,
    "queen-pen": `${di}/products/queen-pen`,
    "ravena-drinking-glass-12.5-oz": `${texxture}/products/ravena-drnkng-glass-tall`,
    "ravena-drinking-glass-8-oz": `${texxture}/products/ravena-drnkng-glass-short`,
    "red-star-glass": `${texxture}/products/red-star-glass`,
    "regatta-candleholder-set-ocean-set-3": `${texxture}/products/regatta-ocean`,
    "regatta-candleholder-set-sunset-set-3": `${texxture}/products/regatta-sunset`,
    "regatta-candleholder-set-white-frost-set-3": `${texxture}/products/regatta-frost`,
    "retread-doormat": `${texxture}/products/retread-doormat`,
    "riverstone-platter": `${texxture}/products/riverstone-platter-large`,
    // "riverstone-platter": `${texxture}/products/riverstone-platter-small`,
    "roger-the-snowman-decoration-large": `${texxture}/products/roger-the-snowman-large`,
    "roger-the-snowman-decoration-medium": `${texxture}/products/roger-the-snowman-medium`,
    "roger-the-snowman-decoration-small": `${texxture}/products/roger-the-snowman-small`,
    "roger-the-snowman-decorations-set-8": `${texxture}/products/roger-the-snowman-set`,
    "roger-the-snowman-decoration-xl": `${texxture}/products/roger-the-snowman`,
    "roger-the-snowman-ornament": `${texxture}/products/roger-the-snowman-ornament`,
    "s.k.i.m.-wallet": `${di}/products/s-k-i-m-wallet`,
    "santiago-fruit-bowl-natural": `${texxture}/products/santiago-fruit-bowl`,
    "scarborough-baskets-set-3": `${texxture}/products/scarborough-baskets`,
    "sedona-pushpins-set-6": `${di}/products/sedona-pushpins-set-6`,
    "serapha-champagne-flute-5.8-oz": `${texxture}/products/serapha-champagne-flute`,
    "serapha-drinking-glass-13.5-oz": `${texxture}/products/serapha-drnkng-glass-short`,
    "serapha-drinking-glass-15.4-oz": `${texxture}/products/serapha-drnkng-glass-tall`,
    "serapha-wine-glass-10.5-oz": `${texxture}/products/serapha-wine-glass`,
    "shakespeare-pen": `${di}/products/shakespeare-pen`,
    "sherwood-tree-extra-large": `${texxture}/products/sherwood`,
    "sherwood-tree-large": `${texxture}/products/sherwood-large`,
    "sherwood-tree-medum": `${texxture}/products/sherwood-medium`,
    "sherwood-tree-small": `${texxture}/products/sherwood-small`,
    "s-hooks-lantern-hooks-set-of-6silver": `${texxture}/products/s-hook-lantern-hooks`,
    "singapore-straws-box-of-20": `${texxture}/products/singapore-straws`,
    "sink-basket-white": `${di}/products/sink-basket`,
    "skater-ornaments-set-of-4assorted": `${texxture}/products/skater-ornaments`,
    "slothy-photoclips": `${di}/products/slothy-photoclips`,
    "soapleaves-soaps-daisy": `${texxture}/products/soap-leaves-flour`,
    "soapleaves-soaps-feathers": `${texxture}/products/soapleaves-feathers`,
    "soapleaves-soaps-green": `${texxture}/products/soap-leaves`,
    "soapleaves-soaps-ivory": `${texxture}/products/soap-leaves`,
    "soapleaves-soaps-pineapples": `${texxture}/products/soapleaves-pineapple`,
    "spacedout-eraser": `${di}/products/spacedout-eraser`,
    "spacedout-pushpins-set-6": `${di}/products/spacedout-pushpins-set-6`,
    "sparrowclips-clips-set-of-4assorted": `${di}/products/sparrowclips-set-4-asst`,
    "spectrum-wall-calendar": `${di}/products/spectrum-wall-calendar`,
    "spirit-candle-dance": `${texxture}/products/spirit-candle-dance`,
    "spirit-candle-embrace": `${texxture}/products/spirit-candle-embrace`,
    "spirit-candle-nurture": `${texxture}/products/spirit-candle-nurture`,
    "spirit-pillar-candle-meditate": `${texxture}/products/spirit-candle-meditate`,
    "springchick-decorations-set-of-8": `${texxture}/products/springchick-decorations`,
    "spririt-candle-zen": `${texxture}/products/spirit-candle-zen`,
    "spyblocker-webcam-covers-no-peeking": `${di}/products/spyblocker-set-6-no-peeking`,
    "spyblocker-webcam-covers-nyc": `${di}/products/spyblocker-set-6-nyc`,
    "squish-drawerstore-organizer-set-black": `${di}/products/squish-drawerstore-set-2`,
    "squish-drawerstore-organizer-set-blush-pink": `${di}/products/squish-drawerstore-set-2`,
    "squish-drawerstore-organizer-set-sage-green": `${di}/products/squish-drawerstore-set-2`,
    "squish-drawerstore-organizer-set-white": `${di}/products/squish-drawerstore-set-2`,
    "squish-stapler-and-remover-set-black": `${di}/products/squish-stapler-remover`,
    "squish-stapler-and-remover-set-blush-pink": `${di}/products/squish-stapler-remover`,
    "squish-stapler-and-remover-set-sage-green": `${di}/products/squish-stapler-remover`,
    "squish-stapler-and-remover-set-white": `${di}/products/squish-stapler-remover`,
    "squish-tape-measure-black": `${di}/products/squish-tape-measure`,
    "squish-tape-measure-blush-pink": `${di}/products/squish-tape-measure`,
    "squish-tape-measure-sage-green": `${di}/products/squish-tape-measure`,
    "squish-tape-measure-white": `${di}/products/squish-tape-measure`,
    "stoneshard-bowl-3.9-x-4.7-x-2-in": `${texxture}/products/stoneshard-bowl-small`,
    "stoneshard-bowl-5.9-x-6.3-x-3.2-in": `${texxture}/products/stoneshard-bowl-medium`,
    "stoneshard-bowl-7.9-x-8.7-x-4.3-in": `${texxture}/products/stoneshard-bowl-large`,
    "stoneshard-candle-coaster": `${texxture}/products/stoneshard-candle-coaster`,
    "stoneshard-plate-11.8-dia-x-0.5-in": `${texxture}/products/stoneshard-plate-large`,
    "stoneshard-plate-6-dia-x-0.5-in": `${texxture}/products/stoneshard-plate-small`,
    "stoneshard-plate-9.8-dia-x-0.5-in": `${texxture}/products/stoneshard-plate-medium`,
    "stoneshard-platter-10-x-4-x-0.5-in": `${texxture}/products/stoneshard-platter-small`,
    "stoneshard-platter-16-x-8-x-0.5-in": `${texxture}/products/stoneshard-platter-large`,
    "stonington-baskets-set-2": `${texxture}/products/stonington-baskets`,
    "sutton-magazine-file": `${di}/products/sutton-magazine-file`,
    "sutton-pencil-cup": `${di}/products/sutton-pencil-cup`,
    "swiperight-adhesive-cleaning-pad-strobe": `${di}/products/swiperight-cleaning-pad-strobe`,
    "tabak-floor-mirror": `${texxture}/products/tabak-floor-mirror`,
    "tabak-mirror-large": `${texxture}/products/tabak-mirror-large`,
    "tabak-mirror-rectangle": `${texxture}/products/tabak-mirror`,
    "tabak-mirror-small": `${texxture}/products/tabak-mirror-small`,
    "tab-paper-sorter-fog": `${di}/products/tab-fog`,
    "tahoe-tree-large-assorted": `${texxture}/products/tahoe-tree-large`,
    "tahoe-tree-medium-assorted": `${texxture}/products/tahoe-tree-medium`,
    "tahoe-trees-set-of-3": `${texxture}/products/tahoe-trees-small`,
    "takara-bottle-rest": `${texxture}/products/takara-bottle-rest`,
    "takara-centerpiece-plate": `${texxture}/products/takara-centerpiece-plate`,
    "takara-coffee-table": `${texxture}/products/takara-coffee-table`,
    "takara-column-shelf": `${texxture}/products/takara-column-shelf`,
    "takara-column-shelf-large": `${texxture}/products/takara-column-shelf-large`,
    "takara-column-shelf-small": `${texxture}/products/takara-column-shelf-small`,
    "takara-command-center": `${texxture}/products/takara-command-center`,
    "takara-cutting-board": `${texxture}/products/takara-cutting-board`,
    "takara-desk-organizer": `${texxture}/products/takara-desk-organizer`,
    "takara-dining-table": `${texxture}/products/takara-dining-table`,
    "takara-display-bowl-giant": `${texxture}/products/takara-display-bowl`,
    "takara-end-table": `${texxture}/products/takara-end-table`,
    "takara-fruit-bowl": `${texxture}/products/takara-fruit-bowl`,
    "takara-keyboard-brush": `${texxture}/products/takara-keyboard-brush`,
    "takara-ladder": `${di}/products/REMOVE`,
    "takara-lantern-large": `${texxture}/products/takara-lantern-large`,
    "takara-lantern-small": `${texxture}/products/takara-lantern-small`,
    "takara-letter-sorter": `${texxture}/products/takara-letter-sorter`,
    "takara-letter-tray": `${texxture}/products/takara-letter-tray`,
    "takara-live-edge-shelf-11.8-x-9.8": `${texxture}/products/takara-live-edge-shlf-small`,
    "takara-live-edge-shelf-15.75-x-9.8": `${texxture}/products/takara-live-edge-shlf-medium`,
    "takara-live-edge-shelf-19.7-x-9.8": `${texxture}/products/takara-live-edge-shlf-large`,
    "takara-live-edge-shelf-23.6-x-9.8": `${texxture}/products/takara-live-edge-shlf-x-large`,
    "takara-magazine-file": `${texxture}/products/takara-magazine-file`,
    "takara-pencil-cup": `${texxture}/products/takara-pencil-cup`,
    "takara-round-table-short": `${texxture}/products/takara-round-table-short`,
    "takara-round-table-tall": `${texxture}/products/takara-round-table`,
    "takara-round-tray": `${texxture}/products/takara-round-tray`,
    "takara-scissors": `${texxture}/products/takara-scissors`,
    "takara-side-table": `${texxture}/products/takara-side-table`,
    "takara-stapler": `${texxture}/products/takara-stapler`,
    "takara-staple-remover": `${texxture}/products/takara-staple-remover`,
    // "takara-staple-remover": `${texxture}/products/upland-scissors`,
    "takara-tape-dispenser": `${texxture}/products/takara-tape-dispenser`,
    "takara-tape-measure": `${texxture}/products/takara-tape-measure`,
    "takara-tray-large": `${texxture}/products/takara-tray-large`,
    "takara-trio-tray": `${texxture}/products/takara-trio-tray`,
    "takara-trough": `${texxture}/products/takara-trough`,
    "takara-wall-basket": `${texxture}/products/takara-wall-basket`,
    "takara-wall-organizer": `${texxture}/products/takara-wall-organizer`,
    "tannenbaum-tree-11-inches-natural": `${texxture}/products/tannenbaum-tree-medium`,
    "tannenbaum-tree-14-inches-natural": `${texxture}/products/tannenbaum-tree-large`,
    "tannenbaum-tree-17-inches-natural": `${texxture}/products/tannenbaum-tree-ex-large`,
    "tannenbaum-tree-35-inches-emerald-green": `${texxture}/products/tannenbaum-tree-giant`,
    "tannenbaum-tree-35-inches-natural": `${texxture}/products/tannenbaum-tree-giant`,
    "tannenbaum-tree-3-inches-natural-set-12": `${texxture}/products/tannenbaum-tree-itty`,
    "tannenbaum-tree-4.5-inches-natural-set-8": `${texxture}/products/tannenbaum-tree-mini`,
    "tannenbaum-tree-5-foot-emerald-green": `${texxture}/products/tannenbaum-tree-small`,
    "tannenbaum-tree-6-inches-natural": `${texxture}/products/tannenbaum-tree-ex-small`,
    "tannenbaum-tree-8-inches-natural": `${texxture}/products/tannenbaum-tree-small`,
    "tannenbaum-tree-extra-large-assorted-greens": `${texxture}/products/tannenbaum-tree-large`,
    "tannenbaum-tree-extra-small-assorted-green": `${texxture}/products/tannenbaum-tree-ex-small-set`,
    "tannenbaum-tree-extra-small-assorted-mod": `${texxture}/products/tannenbaum-tree-ex-small-set`,
    "tannenbaum-tree-itty-bittyassorted-greensset-of-12": `${texxture}/products/tannenbaum-tree-itty`,
    "tannenbaum-tree-itty-bittyassorted-modset-of-12": `${texxture}/products/tannenbaum-tree-itty`,
    "tannenbaum-tree-large-assorted-greens": `${texxture}/products/tannenbaum-tree-medium`,
    "tannenbaum-tree-medium-assorted-greens": `${texxture}/products/tannenbaum-tree-medium-set`,
    "tannenbaum-tree-medium-assorted-mod": `${texxture}/products/tannenbaum-tree-medium-set`,
    "tannenbaum-tree-miniassorted-greensset-of-8": `${texxture}/products/tannenbaum-tree-mini`,
    "tannenbaum-tree-miniassorted-modset-of-8": `${texxture}/products/tannenbaum-tree-mini`,
    "tannenbaum-tree-small-assorted-greens": `${texxture}/products/tannenbaum-tree-small-set`,
    "tannenbaum-tree-small-assorted-mod": `${texxture}/products/tannenbaum-tree-small-set`,
    "taper-candles-10box-of-6ivory": `${texxture}/products/tapers-ivory`,
    "taper-candles-10box-of-6white": `${texxture}/products/tapers-white`,
    "tealight-candles-box-of-12white": `${texxture}/products/tealights-small-set`,
    "tealight-candles-box-of-50white": `${texxture}/products/tealights-large-set`,
    "tealight-candles-set-of-100white": `${texxture}/products/tin-cup-tealights`,
    "terrace-desktop-buddy": `${texxture}/products/terrace-desktop-buddy`,
    "terrazza-bistro-table-sand": `${texxture}/products/terrazza-bistro-table`,
    "terrazza-coffee-table-sand": `${texxture}/products/terrazza-coffee-table`,
    "terrazza-hex-holders-set-of-2": `${texxture}/products/terrazza-hex-holders`,
    "terrazza-pot-blue": `${texxture}/products/terrazza-pot-small`,
    "terrazza-pot-sand": `${texxture}/products/terrazza-pot-small`,
    "toastit-coasters-set-of-8": `${di}/products/toastit-coasters-set-8`,
    "toro-tissue-ring-brass": `${texxture}/products/toro-tissue-ring`,
    "touche-erasers-set-of-2": `${di}/products/touche-erasers-set-2`,
    "transformit-eraser-kit": `${di}/products/transformit-eraser-kit`,
    "trellis-lantern-6.9-x-6.9-x-13-in": `${texxture}/products/trellis-lantern-tall`,
    "trellis-lantern-6.9-x-6.9-x-8.3-in": `${texxture}/products/trellis-lantern-short`,
    "tux-decoration-extra-large": `${texxture}/products/tux-ex-large`,
    "tux-decoration-large": `${texxture}/products/tux-large`,
    "tux-decoration-smallset-of-12": `${texxture}/products/tux-small`,
    "tux-ornament": `${texxture}/products/tux-ornament`,
    "tweed-placemat-black": `${texxture}/products/tweed-placemat`,
    "tweed-placemat-blue": `${texxture}/products/tweed-placemat`,
    "upland-stapler": `${texxture}/products/upland-stapler`,
    "upland-staple-remover": `${texxture}/products/upland-staple-remover`,
    "upland-tape-dispenser": `${texxture}/products/upland-tape-dispenser`,
    "upland-tape-measure": `${texxture}/products/upland-tape-measure`,
    "uzu-hi-ball-glass-17.75-oz": `${texxture}/products/uzu-drinking-glass-tall`,
    "uzu-tumbler-14.3-oz": `${texxture}/products/uzu-drinking-glass-short`,
    "venezia-side-table-white": `${texxture}/products/venezia-side-table`,
    "ventana-lantern-large": `${texxture}/products/ventana-lantern-large`,
    "ventana-lantern-medium": `${texxture}/products/ventana-lantern-medium`,
    "ventana-lantern-small": `${texxture}/products/ventana-lantern-small`,
    "vidra-bowl-dots": `${texxture}/products/vidra-bowl-dots`,
    "vidra-bowl-lines": `${texxture}/products/vidra-bowl-lines`,
    "vidra-bowl-rings": `${texxture}/products/vidra-bowl-rings`,
    "vinestra-organizer": `${texxture}/products/vinestra-organizer`,
    "vinestra-tray-12-x-9-x-2-in": `${texxture}/products/vinestra-tray-large`,
    "vinestra-tray-6-x-9-x-2-in": `${texxture}/products/vinestra-tray-small`,
    "vision-vase-extra-largerectangle": `${texxture}/products/vision-vase-ex-large`,
    "vision-vase-largerectangle": `${texxture}/products/vision-vase-large`,
    "vision-vase-mediumlongrectangle": `${texxture}/products/vision-vases-medium-long`,
    "vision-vase-mediumrectangle": `${texxture}/products/vision-vase-medium`,
    "vision-vase-smallrectangle": `${texxture}/products/vision-vase-small`,
    "vision-vase-trough": `${texxture}/products/vision-vases-trough`,
    "votives-candles-box-of-6white": `${texxture}/products/votives`,
    "wabisabi-hurricane-7-x-6.3-x-8.3-in": `${texxture}/products/wabisabi-hurricane`,
    "wabisabi-votive-3.5-x-3.5-x-3.5-in": `${texxture}/products/wabisabi-votive`,
    "waterhyacinth-baskets-set-2": `${texxture}/products/water-hyacinth-baskets`,
    "watertower-decorative-container": `${texxture}/products/watertower-decorative-containr`,
    "watson-candle-tray-large": `${texxture}/products/watson-candletray-large`,
    "watson-candle-tray-small": `${texxture}/products/watson-candletray-small`,
    "wedge-coasters-set-of-4": `${texxture}/products/wedge-coasters`,
    "whimsiclip-paperclips-cactus": `${di}/products/whimsiclip-paperclp-st-6-cactu`,
    "wirzle-puzzle-helicopter": `${di}/products/wirzle-helicopter`,
    "woodland-creatures-set-of-8assorted": `${texxture}/products/woodland-creatures`,
    "woundup-doodad-cup": `${di}/products/woundup-doodad-cup`,
    "woundup-pencil-cup-brass": `${di}/products/woundup-pencil-cup`,
    "yosemite-coat-hook-long-multiple-hooks-white": `${texxture}/products/yosemite-coat-hook-long`,
    "yosemite-coat-hook-multi": `${texxture}/products/yosemite-coat-hook-short`,
    "yosemite-coat-hook-single": `${texxture}/products/yosemite-coat-hook-single`,
    "yosemite-coat-rack": `${texxture}/products/yosemite-coat-rack`,
    "yosemite-mug-tree": `${texxture}/products/yosemite-mug-tree`,
  };

  const reidrect = (handle) => {
    //check if link is in linkMap
    let link = linkMap[handle];

    //route traffic to corisponding link if it exists or send to welcome screen
    window.location.href = link ? link : "/welcome";
    return null;
  };

  return reidrect(handle);
}

export default Products;
