import { Route, Switch } from "react-router-dom";

import "./App.css";

//pages
import Products from "./pages/Products.js";
import Home from "./pages/Home.js";
import Images from "./pages/Image.js";

//comps
import Loader from "./comps/Loader.js";
import Logo from "./comps/Logo.js";

function App() {
  //BASE URLS
  let urls = {
    texxture: "https://texxturehome.com",
    di: "https://designideas.net",
    wholesale: "https://wholesale.designideas.net",
  };

  // ROUTES
  // "retailers.designideas.net" => "wholesale.designideas.net"
  // "blog.designideas.net" => "texxturehome.com/blogs/news"

  // NOTES------------------
  // server them image rather than redirect
  // collect links that hit the catch all - GUI where you can see this and 1 email a day (if any hits)
  // if landing page point to collection to that, otherwise point to collection or search

  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <Loader />
        <Switch>
          <Route
            path="/product/:handle"
            render={(props) => (
              <Products
                handle={props.match.params.handle}
                urls={urls}
                {...props}
              />
            )}
          />
          <Route
            path="/uploads/images/no_watermark/:sku.jpg"
            render={(props) => (
              <Images handle={props.match.params.sku} urls={urls} {...props} />
            )}
          />
          <Route path="/" render={(props) => <Home urls={urls} {...props} />} />
        </Switch>
      </header>
    </div>
  );
}

export default App;

// <Route
//   path="/uploads/images/no_watermark/:sku.jpg"
//   component={(props) => ImageReidrect(props.match.params.sku)}
// />
// <Route path="/uploads/images/no_watermark/:sku.jpg" component={Image} />

//          component={(props) => ProductReidrect(props.match.params.handle)}
