import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
// import {
//   ApolloClient,
//   InMemoryCache,
//   ApolloProvider,
//   createHttpLink,
// } from "@apollo/client";
// import { setContext } from "@apollo/client/link/context";

// const link = createHttpLink({
//   uri: "https://texture-home.myshopify.com/api/2020-04/graphql.json",
//   headers: {
//     "X-Shopify-Storefront-Access-Token": "026d79c2eda608773cd0befe23056332",
//   },
// });
//
// export const client = new ApolloClient({
//   link: link,
//   cache: new InMemoryCache(),
// });

ReactDOM.render(
  // <ApolloProvider client={client}>
  <Router>
    <App />
  </Router>,
  // </ApolloProvider>,
  document.getElementById("root")
);
